<template>
  <div class="header-print">
    <div class="columns">
      <div class="column is-2">
        <b-image
          :src="require('../../assets/sidebar-dashboard/HK_Logo.png')"
          alt="Logo"
          responsive
          class="is-64x64"
          style="transform: scale(2.3);  margin-left: auto;
                    margin-right: auto;"
        ></b-image>
      </div>
    </div>
    <div class="columns mb-5">
      <div class="column has-text-centered has-text-weight-bold">
        {{ title }} {{ date.toLocaleDateString('ID', options) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title', 'date'],
  data() {
    return {
      today: new Date(),
      options: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
    }
  },
}
</script>
