<template>
  <div class="footer-print">
    <div class="columns">
      <div
        class="column is-12"
        style="height: 3px;
                    background-image: linear-gradient(to right, black 50%, white 0%);
                    background-position: center;
                    background-size: 10px 1px;
                    background-repeat: repeat-x;"
      ></div>
    </div>

    <div class="columns">
      <div class="column is-2">
        WWW.HARGAKURS.COM
      </div>
      <div class="column is-offset-4 is-6 has-text-right">
        Tanggal Dicetak :
        {{ today.toLocaleDateString('ID', options) }}
      </div>
    </div>
    <div class="columns">
      <div
        class="column is-12"
        style="height: 3px;
                    background-image: linear-gradient(to right, black 50%, white 0%);
                    background-position: center;
                    background-size: 10px 1px;
                    background-repeat: repeat-x;"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      today: new Date(),
      options: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
    }
  },
}
</script>
