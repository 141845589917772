<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ReportIndex' }">
            Reporting
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2">
            Revenue
          </span>
        </li>
      </ul>
    </nav>

    <div class="columns is-multiline">
      <div class="column is-4">
        <div class="mb-2 text-grey">Tanggal</div>
        <b-datepicker
          v-model="date"
          locale="id"
          rounded
          placeholder="Pilih Tanggal"
          custom-class="date-input"
          trap-focus
          @input="getRevenueByDate()"
        ></b-datepicker>
      </div>
      <div class="column is-2 is-offset-6">
        <div class="is-pulled-right mt-5 pt-3" @click="printReport()">
          <b-image
            :src="require('@/assets/Print.png')"
            alt="Dashboard Image"
            style="width: 30px;cursor:pointer"
          ></b-image>
        </div>
      </div>

      <div class="column" id="print-report">
        <PrintHeader
          v-if="formatPrint"
          :title="'Laporan Pendapatan '"
          :date="date"
        ></PrintHeader>
        <b-table :data="report" :loading="loading">
          <b-table-column field="date" label="Tanggal">
            {{ date.toLocaleDateString('ID', options) }}
          </b-table-column>

          <b-table-column
            field="totalAddon"
            label="Pendapatan Add On"
            v-slot="props"
          >
            {{ props.row.addOnRevenue | toCurrency }}
          </b-table-column>

          <b-table-column
            field="totalBilling"
            label="Pendapatan Billing"
            v-slot="props"
          >
            {{ props.row.billingRevenue | toCurrency }}
          </b-table-column>

          <b-table-column
            field="totalRevenue"
            label="Total Pendapatan"
            v-slot="props"
          >
            {{ props.row.totalRevenue | toCurrency }}
          </b-table-column>
        </b-table>
        <PrintFooter class="mt-5" v-if="formatPrint"></PrintFooter>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
import PrintHeader from '@/components/print/HeaderPrintReportRevenue.vue'
import PrintFooter from '@/components/print/FooterPrintReportRevenue.vue'
export default {
  components: {
    PrintHeader,
    PrintFooter,
  },
  data() {
    return {
      total: 0,
      loading: false,
      formatPrint: false,
      date: new Date(),
      report: [],
      options: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
    }
  },
  created() {
    this.getRevenueByDate()
  },
  methods: {
    getRevenueByDate: function() {
      this.report = []
      var date = this.convertDate(this.date)
      const params = [`date=${date}`].join('&')
      this.loading = true
      this.$store.dispatch('getRevenueByDate', params).then(response => {
        if (response != null) {
          this.report.push(response)
        } else {
          this.report = []
        }
        this.loading = false
      })
      this.loading = false
    },
    printReport: function() {
      this.formatPrint = true
      const options = {
        name: '_blank',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://unpkg.com/kidlat-css/css/kidlat.css',
          'https://unpkg.com/buefy/dist/buefy.min.css',
          'https://unpkg.com/buefy/dist/buefy.min.js',
        ],
      }
      Vue.use(VueHtmlToPaper, options)
      this.$nextTick(() => {
        this.$htmlToPaper('print-report', null, () => {
          this.formatPrint = false
        })
      })
    },
    convertDate: function(date) {
      var convertedDate =
        date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
      return convertedDate
    },
  },
}
</script>

<style scoped>
.button-import {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
}

::v-deep .date-input {
  background: url('../../assets/Date.png') no-repeat scroll;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: right;
  padding-right: 15px;
  background-origin: content-box;
  background-color: white;
}
</style>
